import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";

// Lazy Loading
const Privacy = React.lazy(() => import("./pages/Privacy"));

const App = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/zh" element={<Home />} />
      <Route path="/tw" element={<Home />} />
      <Route path="/ru" element={<Home />} />
      <Route path="/en" element={<Home />} />
      <Route path="/tr" element={<Home />} />
      <Route path="/id" element={<Home />} />
      <Route path="/in" element={<Home />} />
      <Route path="/jp" element={<Home />} />
      <Route path="/es" element={<Home />} />
      <Route path="/fr" element={<Home />} />
      <Route path="/ar" element={<Home />} />
      <Route
        path="/privacy"
        element={
          <Suspense fallback={<div>Loading...</div>}>
            <Privacy />
          </Suspense>
        }
      />
      <Route path="*" element={<Home />} />
    </Routes>
  </BrowserRouter>
);

export default App;
