import { Col, Row } from "antd";
import FeatureBlock from "./FeatureBlock";

function FeatureBanner() {
  return (
    <div className="front-info">
      <Row className="keypoints">
        <Col md={8} xs={24}>
          <FeatureBlock
            title="h1"
            iconPath="/free.png"
            descriptions={["h1d1", "h1d2"]}
          ></FeatureBlock>
        </Col>
        <Col md={8} xs={24}>
          <FeatureBlock
            title="h2"
            iconPath="/safe.png"
            descriptions={["h2d1", "h2d2"]}
          ></FeatureBlock>
        </Col>
        <Col md={8} xs={24}>
          <FeatureBlock
            title="h3"
            iconPath="/fast.png"
            descriptions={["h3d1", "h3d2"]}
          ></FeatureBlock>
        </Col>
      </Row>
    </div>
  );
}

export default FeatureBanner;
