import {
  FileDoneOutlined,
  QuestionCircleOutlined,
  BulbOutlined,
} from "@ant-design/icons";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import "./DescriptionSession.less";

function DescriptionSession() {
  const { t } = useTranslation();
  return (
    <div>
      <Row className="message-block">
        <Col md={2} xs={6}>
          <BulbOutlined className="center-icon" />
        </Col>
        <Col md={22} xs={18}>
          <div className="word-block">
            <div className="question">{t("g1")}</div>
            <div>{t("g2")}</div>
            <div>{t("g3")}</div>
          </div>
        </Col>
      </Row>

      <Row gutter={16} className="info-block">
        <Col md={12} xs={24}>
          <div className="display-block">
            <Row>
              <Col xl={4} xs={6}>
                <FileDoneOutlined className="center-icon" />
              </Col>
              <Col xl={20} xs={18}>
                <div className="format-desc">
                  <div>{t("g4")}:</div>
                  <div>mp4, webm, mov, wmv</div>
                  <div>{t("g5")}</div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={12} xs={24}>
          <div className="display-block">
            <Row>
              <Col xl={4} xs={6}>
                <QuestionCircleOutlined className="center-icon" />
              </Col>
              <Col xl={20} xs={18}>
                <div className="how-to-use-desc">
                  <div>{t("k1")}:</div>
                  <div className="desc">{t("k2")}</div>
                  <div className="desc">{t("k3")}</div>
                  <div className="desc">{t("k4")}</div>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}
export default DescriptionSession;
