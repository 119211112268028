/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Home.less";
import "antd/dist/antd.less"; // or 'antd/dist/antd.less'
import React, { useState, useRef, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import i18n from "../i18n";
import { QuestionCircleOutlined, BookOutlined } from "@ant-design/icons";

import {
  Switch,
  Form,
  Upload,
  InputNumber,
  Button,
  Progress,
  Popover,
  Slider,
} from "antd";
import { Layout, Row, Col, Spin } from "antd";
import type { UploadProps } from "antd";
import FeatureBanner from "../components/FeatureBanner";
import DescriptionSession from "../components/DescriptionSession";
import LanugageSelector from "../components/LanguageSelector";
import Social from "../components/Social";
import LeaderboardAdComponent from "../components/LeaderboardAdComponent";
import BlockAdComponent from "../components/BlockAdComponent";
import Compressor from "../core/Compressor";
import SideAdComponent from "../components/SideAdComponent";

const { Header, Footer, Content } = Layout;

const normFile = (e: any) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

declare global {
  interface Window {
    ffmpeg: any;
  }
}

let compressor: Compressor | null = null;

// codingProgressStages will track the current coding progress.
// Will set BI accordingly.
const codingProgressStages = [0.1, 1, 5, 20, 50];
const supportedVideoFormats = ["mp4", "webm", "mov", "wmv"];
let curLanguage = window.location.pathname.split("/")[1];
if (curLanguage === "") {
  curLanguage = "en";
}
i18n.changeLanguage(curLanguage);

function Home() {
  // video meta for suggested size computing
  const [videoDuration, setVideoDuration] = useState(0);
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [filename, setFilename] = useState("video");

  // coding states
  const [transProcess, setTransProgress] = useState(0.0);
  const [videoSelected, setVideoSelected] = useState(false);
  const [ffmpegLoaded, setFFmpegLoaded] = useState(false);
  const [startBtnClicked, setStartBtnClicked] = useState(false);
  const [reachedCodingStage, setReachedCodingStage] = useState(0);

  // video data
  const [videoBuf, setVideoBuf] = useState<ArrayBuffer>();
  const [originalVideoBitrate, setOriginalVideoBitrate] = useState(0.0);
  const [originalVideoSize, setOriginalVideoSize] = useState(0.0);

  // coding config
  const [outputFileSize, setOutputFileSizeValue] = useState(0);
  const [suggestedSizeInMB, setSuggestedSizeInMB] = useState(0.0);
  const [transMode, setTransMode] = useState("specifySize");

  // others
  const [errMsg, setErrMsg] = useState("");
  const [showVideoLoadingSpin, setShowVideoLoadingSpin] = useState(false);
  const { t } = useTranslation();

  const originalVideoBitrateRef = useRef(0.0);
  const videoDurationRef = useRef(0.0);
  const videoBufRef = useRef<ArrayBuffer>();

  const suggestSizePopup = (
    <div>
      <div>{t("p1")}</div>
      <div>
        {t("p2")}{" "}
        <a
          href="https://support.google.com/youtube/answer/1722171?hl=en"
          target="blank"
        >
          {t("p3")}
        </a>{" "}
        {t("p4")}
      </div>
      {suggestedSizeInMB > originalVideoSize / 1000000 && (
        <div>
          <hr />
          <div>{t("p5")}</div>
          <div>{t("p6")}</div>
        </div>
      )}
    </div>
  );

  useEffect(() => {
    document.dir = i18n.dir();
  }, []);

  useEffect(() => {
    originalVideoBitrateRef.current = originalVideoBitrate;
  }, [originalVideoBitrate]);

  useEffect(() => {
    videoDurationRef.current = videoDuration;
  }, [videoDuration]);

  useEffect(() => {
    videoBufRef.current = videoBuf;
  }, [videoBuf]);

  useEffect(() => {
    const onPageLoad = () => {
      compressor = new Compressor();
      compressor.errCallback = (errMsg: string) => {
        setErrMsg(errMsg);
      };
      compressor.progressCallback = (progress: number) => {
        setTransProgress(keep2Decimal(progress));
      };
      compressor.ffmpegLoadedCallback = () => {
        setFFmpegLoaded(true);
      };
      compressor.videoStreamBitrateUpdated = (bitrate: number) => {
        setOriginalVideoBitrate(bitrate);
      };
      window.removeEventListener("load", onPageLoad);
    };
    // Check if the page has already loaded
    if (!compressor) {
      if (document.readyState === "complete") {
        onPageLoad();
      } else {
        window.addEventListener("load", onPageLoad);
      }
    }
  });

  useEffect(() => {
    if (reachedCodingStage === codingProgressStages.length) {
      return;
    }
    if (transProcess > codingProgressStages[reachedCodingStage]) {
      gtag(
        "event",
        "coding_progress_" + codingProgressStages[reachedCodingStage]
      );
      setReachedCodingStage(reachedCodingStage + 1);
    }
  }, [transProcess, reachedCodingStage]);

  useEffect(() => {
    if (videoWidth * videoHeight * videoDuration === 0) {
      return;
    }
    let s = getSuggestedFileSizeInMB(videoWidth, videoHeight, videoDuration);
    setSuggestedSizeInMB(keep2Decimal(s));
    if (!startBtnClicked) {
      const originalVideoSizeInMB = originalVideoSize / 1000000;
      s = keep2Decimal(Math.min(originalVideoSizeInMB, s));
      setOutputFileSizeValue(s);
    }
  }, [
    originalVideoSize,
    videoDuration,
    videoWidth,
    videoHeight,
    startBtnClicked,
  ]);

  const video = React.useRef<HTMLVideoElement>(null);
  const downloadAnchor = React.useRef<HTMLAnchorElement>(null);
  const transModeChange = (checked: boolean) => {
    if (checked) {
      setTransMode("autoCalculated");
    } else {
      setTransMode("specifySize");
    }
  };
  const fileSizeTooLargeNotification = () => {
    setErrMsg(
      "Video files larger than 2GB is not supported. Try Local Desktop App like Handbrade."
    );
  };
  const fileExtensionNotSupportedNotification = () => {
    setErrMsg(
      "Your video file is not supported. Please refresh and choose another file."
    );
  };
  function keep2Decimal(n: number) {
    return Math.floor(n * 100) / 100;
  }

  function getVideoMeta(videoArraybuffer: ArrayBuffer) {
    if (!compressor) {
      console.error("compressor not initialized");
      gtag("event", "Error_compressor_not_initialized");
      return;
    }
    compressor.getVideoBitrate(videoArraybuffer).then((bitrate: number) => {
      setOriginalVideoBitrate(bitrate);
    });
    compressor
      .getVideoDimension(videoArraybuffer)
      .then((dimension: Number[]) => {
        setVideoWidth(dimension[0].valueOf());
        setVideoHeight(dimension[1].valueOf());
      });
    compressor
      .getVideoDuration(videoArraybuffer)
      .then((durationInSeconds: number) => {
        setVideoDuration(durationInSeconds);
      });
  }

  function ffmpegCompress(videoBitrate: number, videoArraybuffer: ArrayBuffer) {
    if (!compressor) {
      console.error("compressor not initialized");
      gtag("event", "Error_compressor_not_initialized");
      return;
    }
    compressor
      .compress(videoArraybuffer, videoBitrate)
      .then((vBuffer: ArrayBuffer) => {
        setTransProgress(100.0);
        setDownloadLink(vBuffer, filename + "_compressed.mp4");
      });
  }

  function ffmpegCompressAutoBitrate(videoArraybuffer: ArrayBuffer) {
    if (!compressor) {
      console.error("compressor not initialized");
      gtag("event", "Error_compressor_not_initialized");
      return;
    }
    compressor
      .autoBitrateCompress(videoArraybuffer)
      .then((vBuffer: ArrayBuffer) => {
        setTransProgress(100.0);
        setDownloadLink(vBuffer, filename + "compressed.mp4");
      });
  }

  async function fetchSampleVideo() {
    setShowVideoLoadingSpin(true);
    const testVideoURL =
      "https://cdn.redpandacompress.com/redpandacompress_sample.mp4";
    let blob = await fetch(testVideoURL).then((r) => r.blob());
    gtag("event", "click_sample_video");
    onFileSelected(new File([blob], "redpandacompress_sample.mp4"));
  }

  function arrayContains(arr :string[], target :string) :boolean {
    for(let idx in arr) {
      if (arr[idx].toLowerCase() === target.toLowerCase()) {
        return true;
      }
    }
    return false;
  }

  function onFileSelected(fileObj: any) {
    const filenameSplit = fileObj.name.split(".");
    const fileBasename = filenameSplit.slice(0, -1).join("");
    const extension = filenameSplit[filenameSplit.length - 1];
    setFilename(fileBasename);
    setShowVideoLoadingSpin(true);
    if (fileObj) {
      if (videoSelected) {
        return;
      }
      let video_size_in_mb = keep2Decimal(fileObj.size / 1000000);
      gtag("event", "select_video", {
        video_size_in_mb: video_size_in_mb,
      });
      if (video_size_in_mb > 2000) {
        // 2GB
        fileSizeTooLargeNotification();
        gtag("event", "select_larger_than_2GB_File");
        setShowVideoLoadingSpin(false);
      }
      if (!arrayContains(supportedVideoFormats, extension)) {
        gtag("event", "not_supported_file_" + extension);
        fileExtensionNotSupportedNotification()
        setShowVideoLoadingSpin(false);
        return 
      }
      setVideoSelected(true);
      setOriginalVideoSize(fileObj.size); // in bytes.
      setOutputFileSizeValue(video_size_in_mb);
      setTransProgress(0.0);
      fileObj.arrayBuffer().then((arrayBuffer: ArrayBuffer) => {
        setShowVideoLoadingSpin(false);
        gtag("event", "video_buffer_loaded");
        setVideoBuf(arrayBuffer);
        getVideoMeta(arrayBuffer);
        if (video.current) {
          let videoTag = video.current!;
          let blob = new Blob([arrayBuffer]);
          let src = window.URL.createObjectURL(blob);
          videoTag.src = src + "#t=1";
          videoTag.onloadedmetadata = function (ev) {
            gtag("event", "video_playback_ready");
            setVideoDuration(videoTag.duration);
            setVideoWidth(videoTag.videoWidth);
            setVideoHeight(videoTag.videoHeight);
          };
        }
      });
    }
  }

  function getSuggestedFileSizeInMB(
    width: number,
    height: number,
    length: number
  ) {
    // Youtube recommend encoding setting:
    // 1080p => 10Mbps, as we are really a web tool, will try 3Mpbs per 1080ps seconds.
    let biratePerPixel = 3.0 / 8.0 / (1920 * 1080);
    let sizePerSecond = biratePerPixel * width * height;
    // Since we use fast encodec, assuming that supported bitrate used veryslow
    return sizePerSecond * length;
  }

  const fileSelector: UploadProps = {
    name: "file",
    onChange(info) {
      onFileSelected(info.file.originFileObj);
    },
  };

  function setDownloadLink(fileData: ArrayBuffer, fileName: string) {
    let blob = new Blob([fileData]);
    let src = window.URL.createObjectURL(blob);
    downloadAnchor.current!.href = src;
    downloadAnchor.current!.download = fileName;
  }

  const outputFileSizeOnChange = (newValue: any) => {
    let v = parseFloat(newValue)
    setOutputFileSizeValue(keep2Decimal(v));
  };

  const startTranscoding = () => {
    setStartBtnClicked(true);
    if (
      originalVideoBitrateRef.current === 0 ||
      videoDurationRef.current === 0
    ) {
      setTimeout(() => startTranscoding(), 2000);
      gtag("event", "empty_video_when_transcoding");
      return;
    }
    gtag("event", "start_transcoding");
    if (transMode === "autoCalculated") {
      gtag("event", "select_auto_size_transcoding");
      ffmpegCompressAutoBitrate(videoBufRef.current!);
    } else {
      let overheadInBytes =
        originalVideoSize -
        (originalVideoBitrateRef.current / 8) * 1000 * videoDurationRef.current;
      let bitrate =
        (8 * (outputFileSize * 1000 * 1000 - overheadInBytes)) /
        videoDurationRef.current;
      ffmpegCompress(bitrate, videoBufRef.current!);
    }
  };

  return (
    <HelmetProvider>
      <Layout>
        <Helmet>
          <title>{t("title")}</title>
          <link rel="canonical" href="http://www.redpandacompress.com" />
          <meta name="description" content={t("meta")} />
        </Helmet>
        <Header className="header">
          <div className="logo">
            <img
              className="logo_img"
              src="/logo_md.png"
              width="64px"
              height="64px"
              alt=""
            />
            <div className="logo_words">
              <p className="title">RedPanda Compress</p>
              <p className="subtitle">{t("subtitle")}</p>
            </div>
          </div>
          <div className="header-right-elems">
            <div className="blog">
              <a
                className="a-link"
                href="https://blog.redpandacompress.com"
                target="_blank"
                rel="noreferrer"
              >
                <BookOutlined /> Blog
              </a>
            </div>
            <LanugageSelector lang={curLanguage} />
          </div>
        </Header>
        <Content className="content">
          <Row>
            <Col span={24}>
              <div className="main-block">
                <FeatureBanner />
                <Row>
                  <Col xl={17} lg={24} md={24}>
                    <div className="site-layout-content">
                      <video
                        className="video-preview"
                        ref={video}
                        hidden={!videoSelected || showVideoLoadingSpin}
                        controls
                      ></video>
                      <Form onFinish={startTranscoding}>
                        {showVideoLoadingSpin && (
                          <Spin
                            size="large"
                            className="sample-loading-spin"
                          ></Spin>
                        )}
                        <Form.Item
                          className="upload-dragger"
                          name="dragger"
                          valuePropName="fileList"
                          getValueFromEvent={normFile}
                          noStyle
                          hidden={videoSelected || showVideoLoadingSpin}
                        >
                          <Upload.Dragger
                            className="file-dragger"
                            {...fileSelector}
                          >
                            <Button
                              type="primary"
                              shape="round"
                              className="choose-file-btn"
                            >
                              {t("f1")}
                            </Button>
                            <Button
                              type="default"
                              shape="round"
                              className="try-example-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                fetchSampleVideo();
                              }}
                            >
                              {t("f2")} (4.4MB)
                            </Button>
                            <p className="ant-upload-text">{t("f3")}</p>
                            <p className="ant-upload-text">{t("f4")}</p>
                          </Upload.Dragger>
                        </Form.Item>
                        <div className="trans-config">
                          {transMode === "autoCalculated" && (
                            <div className="auto-calculate-explaination">
                              <div>{t("e2")}</div>
                              <div>
                                {t("e3")}{" "}
                                {/* <a
                              href="https://blog.redpandacompress.com/2022/12/04/how-big-should-a-video-be/"
                              target="blank"
                            >
                              {t("Learn More")}
                            </a> */}
                              </div>
                            </div>
                          )}
                          {transMode === "specifySize" && (
                            <div className="config-form">
                              <Form.Item
                                valuePropName="targetSize"
                                label={t("f5")}
                              >
                                <Slider
                                  className="output-size-slider"
                                  min={0}
                                  max={originalVideoSize / 1024 / 1024.0}
                                  step={0.01}
                                  value={outputFileSize}
                                  onChange={outputFileSizeOnChange}
                                  disabled={transProcess !== 0.0}
                                  aria-label="Value Slider"
                                />
                                {window.innerWidth >= 800 && 
                                  <InputNumber
                                    className="output-fize-input"
                                    value={outputFileSize}
                                    onChange={outputFileSizeOnChange}
                                    disabled={transProcess !== 0.0}
                                    aria-label="Current Number"
                                  />
                                }
                                {window.innerWidth < 800 && 
                                  <span>{outputFileSize}</span>
                                }
                                <span> {t("MB.")} </span>
                                {suggestedSizeInMB !== 0 && suggestedSizeInMB < originalVideoSize / 1000000 && (
                                  <div>
                                    {t("f6")}: {suggestedSizeInMB} {t("MB.")}
                                    <Popover content={suggestSizePopup}>
                                      <QuestionCircleOutlined className="suggest-size-help" />
                                    </Popover>
                                  </div>
                                )}
                              </Form.Item>
                            </div>
                          )}
                          <div className="trans-mode-select">
                            {t("e1")}:{" "}
                            <Switch
                              onChange={transModeChange}
                              disabled={startBtnClicked}
                              aria-label="Enable AutoTranscode"
                            />
                          </div>
                        </div>
                        <div className="submit-group">
                          <Form.Item name="submit-btn">
                            <Button
                              size="large"
                              shape="round"
                              type="primary"
                              htmlType="submit"
                              disabled={transProcess !== 0.0}
                            >
                              {t("e4")}
                            </Button>
                          </Form.Item>
                        </div>
                      </Form>
                      {errMsg !== "" && (
                        <div className="error-msg">{t(errMsg)}</div>
                      )}
                      {ffmpegLoaded &&
                        transProcess !== 0.0 &&
                        errMsg === "" && (
                          <div>
                            <p>
                              {t("e5")}: {transProcess}%
                            </p>
                            <Progress
                              className="progress-bar"
                              strokeColor="#cf4104"
                              percent={transProcess}
                              showInfo={false}
                            />
                            <Button
                              className="download-btn"
                              size="large"
                              shape="round"
                              type="primary"
                              disabled={transProcess !== 100}
                            >
                              <a
                                ref={downloadAnchor}
                                href="#"
                                onClick={() => gtag("event", "download")}
                              >
                                {t("e6")}
                              </a>
                            </Button>
                          </div>
                        )}
                      {(!ffmpegLoaded || transProcess === 0.0) &&
                        errMsg === "" &&
                        videoSelected &&
                        startBtnClicked && (
                          <div className="ffmpeg-loading-hint">
                            <span>{t("e7")}</span> <Spin></Spin>
                            <br />
                            <small>{t("e8")}</small>
                          </div>
                        )}
                    </div>
                  </Col>
                  {window.innerWidth >= 1200 &&
                    navigator.userAgent !== "ReactSnap" && (
                      <Col xl={6} lg={0} md={0}>
                        <SideAdComponent adSlot={"9897888352"} />
                        <SideAdComponent adSlot={"3068772036"} />
                      </Col>
                    )}
                </Row>
              </div>
              {window.innerWidth >= 800 &&
                navigator.userAgent !== "ReactSnap" && (
                  <LeaderboardAdComponent />
                )}
              {window.innerWidth < 800 &&
                navigator.userAgent !== "ReactSnap" && <BlockAdComponent />}
              <DescriptionSession />
            </Col>
          </Row>
        </Content>
        <Footer>
          <Social language={curLanguage}></Social>
          <div className="terms">
            Redpandacompress © 2022-2023 <span> | </span>
            <a href="/privacy" target="blank">
              Privacy Policy
            </a>
            <span> | </span> Support: support@redpandacompress.com
          </div>
          <br />
          <small>
            <a href="https://www.freepik.com/vectors/panda">
              Panda vector created by pch.vector - www.freepik.com
            </a>
          </small>
        </Footer>
      </Layout>
    </HelmetProvider>
  );
}

export default Home;
