const ffmpegFile = "/ffmpeg-worker-mp4-3KZprSsy.js";

export default class Compressor {
  private mainWorker: Worker;
  private secondaryWorkers: Worker[];
  private compressDone: Function;
  private ffmpegLoaded: boolean;
  private videoDuration: number; // in seconds
  private videoWidth: number;
  private videoHeight: number;
  private videoStreamBitrate: number;
  private overallBitrate: number;
  private compressState: string[];
  private intermediaBuffer: ArrayBuffer[];
  private parallelProgress: number[];
  private aggregateProgressTimeoutInterval: NodeJS.Timer;
  private aggregateProgressTimeoutCount: number;
  private compressFinished: boolean;
  private curCodingProgress: number;

  public progressCallback: Function;
  public ffmpegLoadedCallback: Function;
  public errCallback: Function;
  public videoStreamBitrateUpdated: Function;
  public videoDurationUpdated: Function;
  public videoDimensionUpdated: Function;

  constructor() {
    this.mainWorker = new Worker(ffmpegFile);
    this.secondaryWorkers = [];
    this.compressState = [];
    this.intermediaBuffer = [];
    this.progressCallback = () => {};
    this.ffmpegLoadedCallback = () => {};
    this.errCallback = () => {};
    this.videoDurationUpdated = () => {};
    this.videoDimensionUpdated = () => {};
    this.compressDone = (vBuffer: ArrayBuffer) => {};
    this.ffmpegLoaded = false;
    this.videoDuration = 0;
    this.videoWidth = 0;
    this.videoHeight = 0;
    this.videoStreamBitrate = 0;
    this.overallBitrate = 0;
    this.videoStreamBitrateUpdated = () => {};
    this.parallelProgress = [];
    this.aggregateProgressTimeoutInterval = setTimeout(() => {}, 100);
    this.aggregateProgressTimeoutCount = 0;
    this.compressFinished = false;
    this.curCodingProgress = 0;

    this.mainWorker.onmessage = (e) => {
      const msg = e.data;
      switch (msg.type) {
        case "stderr":
          if (!this.ffmpegLoaded) {
            this.ffmpegLoaded = true;
            this.initializeSecondaryWorkers();
            this.ffmpegLoadedCallback();
          }
          const ffMsg = String(msg.data);
          this.parseMainWorkerFFMessage(ffMsg);
          break;
        case "done":
          if (msg.data.MEMFS[0]) {
            let outArrayBuffer = msg.data.MEMFS[0].data;
            let vBuffer = this.removeTailingZeros(outArrayBuffer);
            this.compressFinished = true;
            this.compressDone(vBuffer);
          }
          break;
        default:
          break;
      }
    };
  }

  private initializeSecondaryWorkers() {
    const numOfCores = navigator.hardwareConcurrency;
    for (let i = 0; i < Math.ceil(numOfCores / 2); i++) {
      let secondaryWorker = new Worker(ffmpegFile);
      this.secondaryWorkers.push(secondaryWorker);
      this.compressState.push("ready");
      this.intermediaBuffer.push(new Uint16Array(0));
      this.parallelProgress.push(0.0);
    }
    for (let i = 0; i < this.secondaryWorkers.length; i++) {
      this.secondaryWorkers[i].onmessage = (e) => {
        const msg = e.data;
        switch (msg.type) {
          case "stderr":
            const ffMsg = String(msg.data);
            this.parseSecondaryWorkerFFMessage(i, ffMsg);
            break;
          case "done":
            if (msg.data.MEMFS[0]) {
              const index = i;
              let outArrayBuffer = msg.data.MEMFS[0].data;
              let vBuffer = this.removeTailingZeros(outArrayBuffer);
              this.segmentCompressDone(index, vBuffer);
            }
            break;
          default:
            break;
        }
      };
    }
  }

  public compress(
    videoBuffer: ArrayBuffer,
    bitrate: number
  ): Promise<ArrayBuffer> {
    bitrate = Math.floor(bitrate / 1000);
    let brString = bitrate.toString() + "K";
    if (this.videoDuration > 8) {
      // the main idea is that each worker will work on 1/4 of the video and then
      // they will merge together. after all passed
      for (let i = 0; i < this.secondaryWorkers.length; i++) {
        const segmentStartStr = this.getSegmentStart(i, this.videoDuration);
        const segmentDurationStr = this.getSegmentDuration(this.videoDuration);
        let args = [
          "-i",
          "input.mp4",
          "-ss",
          segmentStartStr,
          "-t",
          segmentDurationStr,
          "-c:v",
          "libx264",
          "-preset",
          "veryfast",
          "-b:v",
          brString,
          "-maxrate",
          brString,
          "-bufsize",
          "-2M",
          "-c:a",
          "copy",
          "out.mp4",
        ];
        this.secondaryWorkers[i].postMessage({
          type: "run",
          arguments: args,
          MEMFS: [
            {
              name: "input.mp4",
              data: videoBuffer,
            },
          ],
        });
      }
    } else {
      let args = [
        "-i",
        "input.mp4",
        "-c:v",
        "libx264",
        "-preset",
        "veryfast",
        "-b:v",
        brString,
        "-maxrate",
        brString,
        "-bufsize",
        "-2M",
        "-c:a",
        "copy",
        "out.mp4",
      ];
      this.mainWorker.postMessage({
        type: "run",
        arguments: args,
        MEMFS: [
          {
            name: "input.mp4",
            data: videoBuffer,
          },
        ],
      });
    }
    return new Promise<ArrayBuffer>((resolve, reject) => {
      this.compressDone = resolve;
    });
  }

  public autoBitrateCompress(videoBuffer: ArrayBuffer): Promise<ArrayBuffer> {
    let args = [
      "-i",
      "input.mp4",
      "-c:v",
      "libx264",
      "-preset",
      "slow",
      "-c:a",
      "copy",
      "out.mp4",
    ];
    this.mainWorker.postMessage({
      type: "run",
      arguments: args,
      MEMFS: [
        {
          name: "input.mp4",
          data: videoBuffer,
        },
      ],
    });
    return new Promise<ArrayBuffer>((resolve, reject) => {
      this.compressDone = resolve;
    });
  }

  public getVideoBitrate(vBuffer: ArrayBuffer): Promise<number> {
    if (this.videoStreamBitrate !== 0) {
      return new Promise<number>((resolve, reject) => {
        resolve(this.videoStreamBitrate);
      });
    }
    let args = ["-i", "input.mp4"];
    this.mainWorker.postMessage({
      type: "run",
      arguments: args,
      MEMFS: [
        {
          name: "input.mp4",
          data: vBuffer,
        },
      ],
    });
    return new Promise<number>((resolve, reject) => {
      this.videoStreamBitrateUpdated = resolve;
    });
  }

  public getVideoDuration(vBuffer: ArrayBuffer): Promise<number> {
    if (this.videoDuration !== 0) {
      return new Promise<number>((resolve, reject) => {
        resolve(this.videoDuration);
      });
    }
    let args = ["-i", "input.mp4"];
    this.mainWorker.postMessage({
      type: "run",
      arguments: args,
      MEMFS: [
        {
          name: "input.mp4",
          data: vBuffer,
        },
      ],
    });
    return new Promise<number>((resolve, reject) => {
      this.videoDurationUpdated = resolve;
    });
  }

  public getVideoDimension(vBuffer: ArrayBuffer): Promise<Number[]> {
    if (this.videoWidth !== 0) {
      return new Promise<Number[]>((resolve, reject) => {
        resolve([this.videoWidth, this.videoHeight]);
      });
    }
    let args = ["-i", "input.mp4"];
    this.mainWorker.postMessage({
      type: "run",
      arguments: args,
      MEMFS: [
        {
          name: "input.mp4",
          data: vBuffer,
        },
      ],
    });
    return new Promise<Number[]>((resolve, reject) => {
      this.videoDimensionUpdated = resolve;
    });
  }

  private removeTailingZeros(videoArraybuffer: ArrayBuffer): ArrayBuffer {
    let view = new Uint32Array(videoArraybuffer);
    let i = view.length - 1;
    for (i; i > 0; i--) {
      if (view[i] !== 0) {
        break;
      }
    }
    return videoArraybuffer.slice(0, i + 1);
  }

  private parseMainWorkerFFMessage(ffMsg: string) {
    this.parseError(ffMsg);
    this.parseVideoStreamBitrate(ffMsg);
    this.parseVideoDuration(ffMsg);
    this.parseVideoDimension(ffMsg);
    // Progress is returned from this error output. Need to get the progress format as: time=00:00:00.29
    this.parseCompressProgress(ffMsg);
  }

  private parseSecondaryWorkerFFMessage(workerIndex: number, ffMsg: string) {
    this.parseError(ffMsg);
    this.parseParallelCompressProgress(workerIndex, ffMsg);
  }

  private parseParallelCompressProgress(workerIndex: number, ffMsg: string) {
    const progressTimeRegex = /time=\d\d:\d\d:\d\d\.\d\d/g;
    const found = ffMsg.match(progressTimeRegex);
    if (found !== null) {
      const timeElems = found[0].split("=")[1].split(".")[0].split(":");
      if (timeElems.length !== 3) {
        console.error("Failed to parse time", ffMsg);
        return;
      }
      const hours = timeElems[0];
      const minutes = timeElems[1];
      const seconds = timeElems[2];
      const milliseoncds = found[0].split(".")[1];
      let curProgress =
        parseInt(hours) * 60 * 60 + parseInt(minutes) * 60 + parseInt(seconds);
      if (milliseoncds !== "00") {
        curProgress += parseFloat(milliseoncds) / 100.0;
      }
      const segmentDuration = this.videoDuration / this.secondaryWorkers.length;
      const transProcess = Math.min(
        99.5,
        Math.round((10000.0 * curProgress) / segmentDuration) / 100
      );
      if (transProcess > this.parallelProgress[workerIndex]) {
        this.parallelProgress[workerIndex] = transProcess;
        let overallProgress =
          this.parallelProgress.reduce((a, b) => a + b, 0) /
          this.parallelProgress.length;
        overallProgress = this.decorateProgress(overallProgress);
        if (overallProgress > 99) {
          // hack for final update
          clearInterval(this.aggregateProgressTimeoutInterval);
          this.aggregateProgressTimeoutInterval = setInterval(() => {
            if (this.compressFinished) {
              clearInterval(this.aggregateProgressTimeoutInterval);
              return;
            }
            this.aggregateProgressTimeoutCount += 1;
            let overallProgress =
              this.parallelProgress.reduce((a, b) => a + b, 0) /
              this.parallelProgress.length;
            overallProgress += this.aggregateProgressTimeoutCount * 0.07;
            overallProgress = Math.min(99.8, overallProgress);
            if (this.curCodingProgress < overallProgress) {
              this.curCodingProgress = overallProgress;
              this.progressCallback(overallProgress);
            }
          }, 500);
        }
        this.progressCallback(overallProgress);
      }
    }
  }

  private decorateProgress(progress: number) {
    // this function make progress at first seemed fast. Gain conversion.
    const p = progress / 100.0;
    return 100.0 * (1.515 * p * p * p - 2.9545 * p * p + 2.439 * p);
  }

  private segmentCompressDone(workerIndex: number, vBuffer: ArrayBuffer) {
    switch (this.compressState[workerIndex]) {
      case "ready":
        this.compressState[workerIndex] = "mp4-done";
        // need to copy into ts file
        let args = ["-i", "input.mp4", "-c", "copy", "out.ts"];
        this.secondaryWorkers[workerIndex].postMessage({
          type: "run",
          arguments: args,
          MEMFS: [
            {
              name: "input.mp4",
              data: vBuffer,
            },
          ],
        });
        break;
      case "mp4-done":
        this.compressState[workerIndex] = "ts-done";
        this.intermediaBuffer[workerIndex] = vBuffer;
        this.aggregateSegments();
        break;
      default:
        console.error("unknown state", this.compressState[workerIndex]);
        break;
    }
  }

  private aggregateSegments() {
    for (let i = 0; i < this.compressState.length; i++) {
      if (this.compressState[i] !== "ts-done") {
        return;
      }
    }
    // All TS done, will let main worker create a complete file.
    let concatStr = "concat:";
    let memfs = [];
    for (let i = 0; i < this.intermediaBuffer.length; i++) {
      concatStr += "p" + i + ".ts";
      if (i !== this.intermediaBuffer.length - 1) {
        concatStr += "|";
      }
      memfs.push({ name: "p" + i + ".ts", data: this.intermediaBuffer[i] });
    }
    let args = ["-i", concatStr, "-c", "copy", "out.mp4"];
    this.mainWorker.postMessage({
      type: "run",
      arguments: args,
      MEMFS: memfs,
    });
  }

  private getSegmentStart(workerIndex: number, videoDuration: number): string {
    const numOfWorkers = this.secondaryWorkers.length;
    const segmentDuration = Math.ceil(videoDuration / numOfWorkers);
    const segmentStart = segmentDuration * workerIndex;
    return this.secondsToHHMMSSFormat(segmentStart);
  }

  private getSegmentDuration(videoDuration: number): string {
    const numOfWorkers = this.secondaryWorkers.length;
    const segmentDuration = Math.ceil(videoDuration / numOfWorkers);
    return this.secondsToHHMMSSFormat(segmentDuration);
  }

  private secondsToHHMMSSFormat(seconds: number): string {
    const hour = Math.floor(seconds / 3600);
    let hourStr = hour.toString();
    if (hour < 10) {
      hourStr = "0" + hourStr;
    }
    const minutes = Math.floor((seconds % 3600) / 60);
    let minStr = minutes.toString();
    if (minutes < 10) {
      minStr = "0" + minStr;
    }
    const sec = Math.floor(seconds % 60);
    let secStr = sec.toString();
    if (sec < 10) {
      secStr = "0" + secStr;
    }
    return hourStr + ":" + minStr + ":" + secStr;
  }

  private parseCompressProgress(ffMsg: string) {
    const progressTimeRegex = /time=\d\d:\d\d:\d\d\.\d\d/g;
    const found = ffMsg.match(progressTimeRegex);
    if (found !== null) {
      const timeElems = found[0].split("=")[1].split(".")[0].split(":");
      if (timeElems.length !== 3) {
        console.error("Failed to parse time", ffMsg);
        return;
      }
      const hours = timeElems[0];
      const minutes = timeElems[1];
      const seconds = timeElems[2];
      const milliseoncds = found[0].split(".")[1];
      let curProgress =
        parseInt(hours) * 60 * 60 + parseInt(minutes) * 60 + parseInt(seconds);
      if (milliseoncds !== "00") {
        curProgress += parseFloat(milliseoncds) / 100.0;
      }
      const transProcess = Math.min(
        99.5,
        Math.round((10000.0 * curProgress) / this.videoDuration) / 100
      );
      if (this.curCodingProgress < transProcess) {
        this.curCodingProgress = transProcess;
        this.progressCallback(transProcess);
      }
    }
  }

  private parseError(ffMsg: string) {
    let DecoderErrorRegex = /Decoder\s[\w()\s]+not\sfound/g;
    const docoderError = ffMsg.match(DecoderErrorRegex);
    if (docoderError !== null) {
      this.errCallback("e9");
      const codec = docoderError[0].match(/codec\s[\w]+/g)![0];
      gtag("event", "bad_v_format_" + codec.substring(6));
    }
  }

  private parseVideoDuration(ffMsg: string) {
    let videoDurationRegex = /Duration: \d\d:\d\d:\d\d.\d\d,/g;
    const durationFound = ffMsg.match(videoDurationRegex);
    if (durationFound !== null) {
      const items = durationFound[0].split(" ")[1].split(":");
      let hours = parseInt(items[0]);
      let minutes = parseInt(items[1]);
      let seconds = parseInt(items[2].split(".")[0]);
      this.videoDuration = hours * 3600 + minutes * 60 + seconds;
      this.videoDurationUpdated(this.videoDuration);
    }
  }

  private parseVideoStreamBitrate(ffMsg: string) {
    // TODO(Fei) if it is not kb/s, but mb/s is this possible?
    const overallBitrateRegex = /Duration:[\w\d\s.()/,[:\]]+\s\d+\skb\/s/g;
    const overallBitrateFound = ffMsg.match(overallBitrateRegex);
    if (overallBitrateFound !== null) {
      const items = overallBitrateFound[0].split(",");
      const ss = items[items.length - 1].split(" ");
      this.overallBitrate = parseInt(ss[ss.length - 2]);
      return;
    }
    const videoBitrateRegex = /Video:[\w\d\s()/,[:\]]+\s\d+\skb\/s/g;
    const videoBitrateFound = ffMsg.match(videoBitrateRegex);
    if (videoBitrateFound !== null) {
      const items = videoBitrateFound[0].split(",");
      this.videoStreamBitrate = parseInt(items[items.length - 1].split(" ")[1]);
      this.videoStreamBitrateUpdated(this.videoStreamBitrate);
    }
    const audioBitrateRegex = /Audio:[\w\d\s()/,[:\]]+\s\d+\skb\/s/g;
    const audioBitrateFound = ffMsg.match(audioBitrateRegex);
    if (audioBitrateFound !== null) {
      const items = audioBitrateFound[0].split(",");
      const audioStreamBitrate = parseInt(
        items[items.length - 1].split(" ")[1]
      );
      this.videoStreamBitrate = this.overallBitrate - audioStreamBitrate;
      this.videoStreamBitrateUpdated(this.videoStreamBitrate);
    }
  }

  private parseVideoDimension(ffMsg: string) {
    const videoDimensionRegex = /\s\d+x\d+\s/g;
    const videoDimensionStr = ffMsg.match(videoDimensionRegex);
    if (videoDimensionStr !== null) {
      const widthHeight = videoDimensionStr[0].split("x");
      this.videoWidth = parseInt(widthHeight[0]);
      this.videoHeight = parseInt(widthHeight[1]);
      this.videoDimensionUpdated([this.videoWidth, this.videoHeight]);
      return;
    }
  }
}
