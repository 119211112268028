import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  'en': {
    translation: {
      "title": "Compress Video Online Free - No login or install | RedPanda",
      "meta": "Fast and safe online video compression tool to reduce file size. No subscription, no cookie, no watermark. Unlimited usage with information security. Compressible video size up to 2GB.",
      "subtitle": "Free Online Video Compression Tool",
      "h1": "Free",
      "h1d1": "No subscription, No login, No cookie",
      "h1d2": "No watermark, Unlimited usage.",
      "h2": "Safe",
      "h2d1": "Video is processed in your browser.",
      "h2d2": "No uploads. No software install.",
      "h3": "High Quality",
      "h3d1": "Blazingly fast",
      "h3d2": "Compress to desired size with little visual difference",
      "f1": "Choose a file...",
      "f2": "Try Sample Video",
      "f3": "or",
      "f4": "Drag and drop a video file to start. (max 2GB)",
      "f5": "Output file size",
      "f6": "Suggested Size",
      "p1": "Size estimated based on 3MB/s for 1080p videos.",
      "p2": "See",
      "p3": "Youtube's advice",
      "p4": "for further suggestions",
      "p5": "Selected video is probably well compressed.",
      "p6": "Further compression might damage image quality.",
      "e1": "Not sure about best size? Try Automated Compression",
      "e2": "Size would be calculated automatically during compression.",
      "e3": "This takes longer time but will give you best compression size and image quality.",
      "e4": "Start Compression",
      "e5": "Compression Progress",
      "e6": "Download",
      "e7": "Allocating Resources",
      "e8": "This usually takes around 10 seconds and it is an one-time setup.",
      "e9": "Sorry, this video format is not supported yet.",
      "g1": "Have a too large video for uploading to Instagram, Facebook, YouTube, TikTok or for sending via Email?",
      "g2": "You can easily compress video online and reduce video size without losing much quality with our online tool FOR FREE.",
      "g3": "Maximun supported file size is 2GB.",
      "g4": "Supports the following formats",
      "g5": "Compressed Video will be in mp4 format.",
      "k1": "How to use",
      "k2": 'Step1: Click on "Select file" or drag and drop a file directly onto the boxed area.',
      "k3": 'Step2: Choose desired output file size.',
      "k4": 'Step3: Click on the "Start Compression" button and download via "Download" button after it is done.'
  }
  },
  'zh': {
    translation: {
      "title": "免费在线压缩视频 - 无需任何注册登陆或软件下载 | Redpanda",
      "meta": "快速且安全的在线视频压缩。没有订阅，没有cookie，没有水印。无限制使用，绝对信息安全。可压缩视频大小高达2GB。",
      "subtitle": "免费的在线视频压缩工具",
      "h1": "免费",
      "h1d1": "无需会员，无需登录，不收集用户信息",
      "h1d2": "没有水印，没有使用次数限制",
      "h2": "安全",
      "h2d1": "视频压缩在您的浏览器上完成",
      "h2d2": "不需要上传视频，也不需要安装本地软件",
      "h3": "高质量",
      "h3d1": "压缩运行速度非常快",
      "h3d2": "保证画质的同时，压缩到您想要的视频大小",
      "f1": "选择本地文件...",
      "f2": "尝试实例视频",
      "f3": "或",
      "f4": "拖拽视频文件到该区域以开始处理 (最大2GB)",
      "f5": "输出文件大小", 
      "f6": "建议文件大小",
      "p1": "建议文件大小基于在 1080p 的清晰度下为 3MB/s",
      "p2": "查看",
      "p3": "Youtube的建议",
      "p4": "获取更多关于视频大小的建议",
      "p5": "所选视频已被较好压缩，",
      "p6": "进一步压缩可能会损坏图像质量。",
      "e1": "不确定最佳视频大小？试试自动压缩",
      "e2": "最佳文件大小会在压缩过程自动计算。",
      "e3": "这需要花费更多时间，但是会提供最佳压缩大小及图像质量。",
      "e4": "开始压缩",
      "e5": "压缩进度",
      "e6": "下载并保存",
      "e7": "正在初始化",
      "e8": "一般情况下需要 10 秒左右，而且是一次性的。",
      "e9": "对不起，此视频格式我们暂时不支持。",
      "g1": "因为视频文件太大，导致无法上传到 Instagram, Facebook, YouTube, TikTok 等平台，或者无法通过邮件发送吗？",
      "g2": "使用这个免费的线上压缩工具，快速压缩视频并保证图像质量",
      "g3": "最大支持的文件大小为 2GB",
      "g4": "支持以下格式的压缩",
      "g5": "压缩后的文件为 mp4 格式",
      "k1": "如何使用",
      "k2": '第一步: 点击"选择本地文件"或者拖拽文件到指定区域',
      "k3": "第二步: 选择想要的目标文件大小",
      "k4": '第三步: 点击"开始压缩"，并且在压缩完成后，点击"下载并保存"保存文件'
    }
  },
  'ru': {
    translation: {
      "title": "Сжать видео онлайн бесплатно - без входа в систему или установки | RedPanda",
      "meta": "Быстрое и безопасное сжатие онлайн-видео. Никаких подписок, никаких файлов cookie, никаких водяных знаков. Неограниченное использование, абсолютная информационная безопасность. Сжатие видео размером до 2 ГБ.",
      "subtitle": "Бесплатный онлайн инструмент для сжатия видео",
      "h1": "Бесплатно",
      "h1d1": "Без подписки, без логина, без cookie.",
      "h1d2": "Без водяных знаков, неограниченное использование.",
      "h2": "Безопасный",
      "h2d1": "Обрабатывайте видео файлы прямо в браузере.",
      "h2d2": "Не нужно загружать свои видео файлы на сервер. Нет установки программного обеспечения.",
      "h3": "Высокое качество",
      "h3d1": "Молниеносно быстро",
      "h3d2": "Сжатие до желаемого размера с небольшим визуальным отличием",
      "f1": "Выберите файл...",
      "f2": "Попробуйте пример видео",
      "f3": "или",
      "f4": "Перетащите файлы сюда (максимум 2GB) ",
      "f5": "Pазмер выходного файла",
      "f6": "Рекомендуемый размер",
      "p1": "Размер оценивается на основе 3 МБ/с для видео 1080p.",
      "p2": "смотрите",
      "p3": "Советы от Youtube",
      "p4": "для дальнейших предложений",
      "p5": "Выбранное видео, вероятно, было хорошо сжато.",
      "p6": "Дальнейшее сжатие может привести к ухудшению качества изображения.",
      "e1": "Не уверены в оптимальном размере? Попробуйте автоматическое сжатие",
      "e2": "Размер будет рассчитан автоматически во время сжатия.",
      "e3": "Это займет больше времени, но даст вам лучший размер сжатия и качество изображения.",  
      "e4": "Начать сжатие",
      "e5": "Прогресс сжатия",
      "e6": "скачать",
      "e7": "инициализация",
      "e8": "Обычно это занимает около 10 секунд и является одноразовой настройкой.",
      "e9": "Извините, этот формат видео еще не поддерживается.",
      "g1": "Слишком большой файл для загрузки в Instagram, Facebook, YouTube, TikTok или для отправки по электронной почте?",
      "g2": "Вы можете легко сжать видео онлайн и уменьшить размер видео без потери качества с помощью нашего онлайн-инструмента БЕСПЛАТНО.",
      "g3": "Максимальный поддерживаемый размер файла - 2GB",
      "g4": "Поддерживает следующие форматы",
      "g5": "Сжатое видео будет в формате mp4",
      "k1": "Как использовать",
      "k2": 'Шаг 1: Нажмите кнопку "Выбрать файл" или перетащите файл непосредственно в область с рамкой',
      "k3": "Шаг 2: Выберите желаемый размер выходного файла",
      "k4": 'Шаг 3: Нажмите на кнопку "Начать сжатие" и загрузите через кнопку "Загрузить" после того, как это будет сделано.'
    }
  },
  'tr': {
    translation: {
      "title": "Video Çevrimiçi Ücretsiz Sıkıştır - Giriş veya kurulum yok | RedPanda",
      "meta": "Dosya boyutunu küçültmek için hızlı ve güvenli çevrimiçi video sıkıştırma aracı. Abonelik yok, çerez yok, filigran yok. Bilgi güvenliği ile sınırsız kullanım. 2 GB'a kadar sıkıştırılabilir video boyutu.",
      "subtitle": "Ücretsiz Çevrimiçi Video Sıkıştırma Aracı",
      "h1": "Ücretsiz",
      "h1d1": "Abonelik yok, Giriş yok, Çerez yok",
      "h1d2": "Filigran yok, Sınırsız kullanım.",
      "h2": " Güvenli",
      "h2d1": "Video tarayıcınızda işlenir",
      "h2d2": "Yükleme yok. Yazılım yüklemesi yok",
      "h3": "Yüksek Kalite",
      "h3d1": "Son derece hızlı",
      "h3d2": "Çok az görsel farkla istenen boyuta sıkıştırın",
      "f1": "Bir dosya seçin...",
      "f2": "Örnek videoyu deneyin",
      "f3": " veya",
      "f4": "Başlamak için bir video dosyasını sürükleyip bırakın (maksimum 2GB)",
      "f5": "Çıktı dosya boyutu",
      "f6": "Önerilen Boyut",
      "p1": "Boyut, 1080p videolar için 3 MB/sn baz alınarak tahmin edilmiştir",
      "p2": "daha fazlasını gör",
      "p3": "Youtube'un tavsiyesi",
      "p4": "daha fazla öneri için",
      "p5": "Seçilen video muhtemelen iyi sıkıştırılmıştır.",
      "p6": "Daha fazla sıkıştırma görüntü kalitesine zarar verebilir.",
      "e1": "En iyi boyuttan emin değil misiniz? Otomatik Sıkıştırmayı Deneyin",
      "e2": "Boyut, sıkıştırma sırasında otomatik olarak hesaplanacaktır.",
      "e3": "Bu daha uzun sürer ancak size en iyi sıkıştırma boyutunu ve görüntü kalitesini verir.",  
      "e4": "Sıkıştırmayı Başlat",
      "e5": "Sıkıştırma İlerlemesi",
      "e6": "İndir",
      "e7": "Başlatma",
      "e8": "Bu genellikle yaklaşık 10 saniye sürer ve tek seferlik bir kurulumdur.",
      "e9": "Üzgünüz, bu video formatı henüz desteklenmiyor.",
      "g1": " Instagram, Facebook, YouTube, TikTok'a yüklemek veya E-posta ile göndermek için çok büyük bir dosyanız mı var?",
      "g2": "Çevrimiçi aracımızla ÜCRETSİZ olarak video boyutunuzu kolayca küçültebilir ve çok fazla kalite kaybetmeden daha küçük hale getirebilirsiniz.",
      "g3": "Maksimum desteklenen dosya boyutu 2GB'tır",
      "g4": "Aşağıdaki formatları destekler",
      "g5": "Sıkıştırılmış Video mp4 formatında olacaktır",
      "k1": "Nasıl kullanılır",
      "k2": 'Adım1: "Dosya seç" üzerine tıklayın veya bir dosyayı doğrudan kutulu alana sürükleyip bırakın',
      "k3": "Adım2: İstediğiniz çıktı dosyası boyutunu seçin",
      "k4": ' Adım3: "Sıkıştırmayı Başlat" düğmesine tıklayın ve tamamlandıktan sonra "İndir" düğmesini kullanarak indirin'
    }
  },
  'tw': {
    translation: {
      "title": "免費在線壓縮視頻 - 無需任何註冊登陸或軟件下載 | Redpanda",
      "meta": "快速且安全的在線視頻壓縮。沒有訂閱，沒有cookie，沒有水印。無限制使用，絕對信息安全。可壓縮視頻大小高達2GB。",
      "subtitle": "免費的線上視頻壓縮工具",
      "h1": "免費",
      "h1d1": "無需會員，無需登錄，不收集使用者資訊",
      "h1d2": "沒有水印，沒有使用次數限制",
      "h2": "安全",
      "h2d1": "視頻壓縮在您留流覽器上完成",
      "h2d2": "不需要上傳視頻，也不需要安裝本地軟體",
      "h3": "高品質",
      "h3d1": "壓縮運行速度非常快",
      "h3d2": "保證畫質的同時，壓縮到您想要的視頻檔大小",
      "f1": "選擇本地文檔...",
      "f2": "嘗試示例視頻",
      "f3": "或",
      "f4": "拖拽視頻檔到該區域以開始處理 (最大2GB) ",
      "f5": "輸出文檔大小",
      "f6": "建議文檔大小",
      "p1": "建議文檔大小基於在 1080p 的清晰度下為 3MB/s",
      "p2": "查看",
      "p3": "YouTube的建議",
      "p4": "獲取更多關於視頻大小的建議",
      "p5": "所選視頻已被較好壓縮，",
      "p6": "進一步壓縮可能會損壞圖像質量。",
      "e1": "不確定最佳視頻大小？試試自動壓縮",
      "e2": "最佳文件大小會在壓縮過程自動計算。",
      "e3": "這需要更長的時間，但會為您提供最佳的壓縮大小和圖像質量。",  
      "e4": "開始壓縮",
      "e5": "壓縮進度",
      "e6": "下載並保存",
      "e7": "正在初始化",
      "e8": "一般情況下需要 10 秒左右，而且是一次性的。",
      "e9": "抱歉，尚不支持此視頻格式。",
      "g1": "因為視頻文檔太大，導致無法上傳到 Instagram, Facebook, YouTube, TikTok 等平臺，或者無法通過郵件發送嗎？",
      "g2": "使用這個免費的線上壓縮工具，快速壓縮視頻並保證視頻品質。",
      "g3": "最大支持的文檔大小為 2GB",
      "g4": "支持以下格式的壓縮",
      "g5": "壓縮後的文檔為mp4 格式",
      "k1": "如何使用",
      "k2": '第一步: 點擊"選擇本地文檔"或者拖拽檔到指定區域',
      "k3": "第二步: 選擇想要的目标文件大小",
      "k4": '第三步: 點擊"開始壓縮"，並且在壓縮完成後，點擊"下載並保存"保存文檔'
    }
  },
  'id': {
    translation: {
      "title": "Kompres Video Online Gratis - Tanpa login atau instal | Redpanda",
      "meta": "Alat kompresi video online yang cepat dan aman untuk mengurangi ukuran file. Tidak ada langganan, tidak ada cookie, tidak ada tanda air. Penggunaan tanpa batas dengan keamanan informasi. Ukuran video yang dapat dikompresi hingga 2GB.",
      "subtitle": "Alat Kompresi Video Gratis Secara Online",
      "h1": "Gratis",
      "h1d1": "Tidak ada berlangganan, tidak perlu masuk log, tidak ada cookie",
      "h1d2": "Tidak ada watermark, penggunaan tidak terbatas",
      "h2": "Aman",
      "h2d1": "Video diproses di browser Anda",
      "h2d2": "Tidak ada unggahan. Tidak ada instal perangkat lunak.",
      "h3": "Kualitas tinggi",
      "h3d1": "Cepat sekali",
      "h3d2": "Kompres sampai ukuran yang diinginkan dengan sedikit perbedaan visual",
      "f1": "Pilih berkas...",
      "f2": "Coba video sampel",
      "f3": "atau",
      "f4": "Seret dan lepaskan berkas video untuk memulai. (maks 2GB)",
      "f5": "Ukuran file keluaran",
      "f6": "Ukuran yang Direkomendasikan",
      "p1": "Ukuran diperkirakan berdasarkan 3MB/s untuk video 1080p.",
      "p2": "Lihat",
      "p3": "Saran Youtube",
      "p4": "untuk saran lebih lanjut",
      "p5": "Video yang dipilih mungkin terkompresi dengan baik.",
      "p6": "Kompresi lebih lanjut dapat merusak kualitas gambar.",
      "e1": "Tidak yakin tentang ukuran terbaik? Coba Kompresi Otomatis",
      "e2": "Ukuran akan dihitung secara otomatis saat kompresi.",
      "e3": "Ini memakan waktu yang lebih lama, tapi akan memberi Anda ukuran kompresi terbaik dan kualitas gambar.",
      "e4": "Mulai Kompresi",
      "e5": "Progres Kompresi",
      "e6": "Unduh",
      "e7": "menginisialisasi",
      "e8": "Ini biasanya memakan waktu sekitar 10 detik dan ini adalah pengaturan sekali pakai.",
      "e9": "Maaf, format video ini belum didukung.",
      "g1": "Memiliki video terlalu besar untuk diunggah ke Instagram, Facebook, YouTube, TikTok, atau untuk dikirim via Email?",
      "g2": "Anda dapat dengan mudah mengurangi ukuran video Anda dan membuatnya lebih kecil tanpa kehilangan banyak kualitas dengan alat online kami GRATIS.",
      "g3": "Ukuran file maksimum yang didukung adalah 2GB.",
      "g4": "Mendukung format berikut:",
      "g5": "Video yang dikompresi akan dalam format mp4.",
      "k1": "Cara penggunaan",
      "k2": 'Langkah 1. Klik pada "Pilih file" atau seret dan jatuhkan file langsung ke area kotak.',
      "k3": "Langkah 2. Pilih ukuran file keluaran yang diinginkan.",
      "k4": 'Langkah 3. Klik tombol "Mulai Kompresi" dan unduh melalui tombol "Unduh" setelah selesai.'
    }
  },
  'in': {
    translation: {
      "title": "कंप्रेस वीडियो ऑनलाइन मुफ़्त - कोई लॉगिन या इंस्टॉल नहीं | Redpanda",
      "meta": "फ़ाइल आकार को कम करने के लिए तेज़ और सुरक्षित ऑनलाइन वीडियो संपीड़न उपकरण। कोई सदस्यता नहीं, कोई कुकी नहीं, कोई वॉटरमार्क नहीं। सूचना सुरक्षा के साथ असीमित उपयोग। 2GB तक कंप्रेसेबल वीडियो साइज।",
      "subtitle": "मुफ्त ऑनलाइन वीडियो संपीड़न उपकरण",
      "h1": "नि: शुल्क",
      "h1d1": "कोई सदस्यता नहीं, कोई लॉगिन नहीं, कोई कुकीज़ नहीं",
      "h1d2": "कोई वॉटरमार्क नहीं, असीमित उपयोग",
      "h2": "सुरक्षित",
      "h2d1": "वीडियो आपके ब्राउज़र में संसाधित किया जाता है",
      "h2d2": "कोई अपलोड नहीं। कोई सॉफ़्टवेयर स्थापित नहीं है।",
      "h3": "उच्च गुणवत्ता",
      "h3d1": "बहुत तेज",
      "h3d2": "थोड़ा दृश्य अंतर के साथ वांछित आकार में संपीड़ित करें",
      "f1": "एक फ़ाइल चुनें...",
      "f2": "नमूना वीडियो का प्रयास करें",
      "f3": "या",
      "f4": "प्रारंभ करने के लिए एक वीडियो फ़ाइल खींचें और छोड़ें। (अधिकतम 2GB)",
      "f5": "आउटपुट फ़ाइल का आकार", 
      "f6": "अनुशंसित आकार",
      "p1": "आकार अनुमान 1080p वीडियो के लिए 3MB/s पर आधारित हैं",
      "p2": "देखो",
      "p3": "यूट्यूब सलाह",
      "p4": "आगे के सुझावों के लिए",
      "p5": "चयनित वीडियो अच्छी तरह से संपीड़ित हो सकता है।",
      "p6": "आगे संपीड़न छवि गुणवत्ता को नुकसान पहुंचा सकता है।",
      "e1": "सर्वश्रेष्ठ आकार के बारे में सुनिश्चित नहीं हैं? स्वचालित संपीड़न का प्रयास करें",
      "e2": "संपीड़न के दौरान आकार की स्वचालित रूप से गणना की जाएगी",
      "e3": "इसमें अधिक समय लगता है लेकिन आपको सर्वोत्तम संपीड़न आकार और छवि गुणवत्ता प्रदान करेगा",
      "e4": "संपीड़न प्रारंभ करें",
      "e5": "संपीड़न प्रगति",
      "e6": "डाउनलोड",
      "e7": "शुरु कर रहा है",
      "e8": "इसमें आमतौर पर लगभग 10 सेकंड लगते हैं और यह एक बार का सेटअप है",
      "e9": "क्षमा करें, यह वीडियो प्रारूप वर्तमान में समर्थित नहीं है",
      "g1": "क्या कोई वीडियो Instagram, Facebook, YouTube, TikTok पर अपलोड करने या ईमेल के माध्यम से भेजने के लिए बहुत बड़ा है?",
      "g2": "मुफ़्त में हमारे ऑनलाइन टूल से आप आसानी से वीडियो को ऑनलाइन कंप्रेस कर सकते हैं और गुणवत्ता खोए बिना वीडियो का आकार कम कर सकते हैं।",
      "g3": "अधिकतम समर्थित फ़ाइल का आकार 2GB है",
      "g4": "निम्न स्वरूपों का समर्थन करता है",
      "g5": "कंप्रेस्ड वीडियो mp4 फॉर्मेट में होगा",
      "k1": "का उपयोग कैसे करें",
      "k2": 'Step1: "फ़ाइल चुनें" पर क्लिक करें या फ़ाइल को सीधे बॉक्सिंग क्षेत्र में खींचें और छोड़ें।',
      "k3": "Step2: वांछित आउटपुट फ़ाइल आकार चुनें",
      "k4": 'Step3: "प्रारंभ संपीड़न" बटन पर क्लिक करें और इसे पूरा करने के बाद "डाउनलोड" बटन के माध्यम से डाउनलोड करें'
    }
  },
  'jp': {
    translation: {
      "title": "ビデオをオンラインで無料圧縮 - ログインやインストールは不要 | Redpanda",
      "meta": "ファイル サイズを縮小するための高速で安全なオンライン ビデオ圧縮ツール。サブスクリプション、Cookie、または透かしはありません。情報セキュリティのため使い放題。最大 2GB の圧縮可能なビデオ サイズ。",
      "subtitle": "無料のオンライン動画圧縮ツール",
      "h1": "無料",
      "h1d1": "会員登録不要、ログイン不要、ユーザー情報収集不要",
      "h1d2": "透かしなし、使用制限なし",
      "h2": "安全",
      "h2d1": "ビデオはブラウザで処理されます。",
      "h2d2": "アップロードはありません。ソフトウェアのインストールはありません。",
      "h3": "高品質",
      "h3d1": "圧縮は非常に高速に実行されます",
      "h3d2": "品質を維持しながら、目的のビデオ サイズに圧縮します",
      "f1": "ドキュメントを選択...",
      "f2": "サンプル動画を試す",
      "f3": "または",
      "f4": "ここにファイルをドラッグします (最大2GB)",
      "f5": "出力ファイルサイズ", 
      "f6": "推奨ファイルサイズ",
      "p1": "1080p 解像度で 3MB/秒に基づく推奨ファイル サイズ",
      "p2": "見る",
      "p3": "Youtubeのアドバイス",
      "p4": "さらなる提案のために",
      "p5": "選択したビデオはすでに十分に圧縮されています。",
      "p6": "さらに圧縮すると、画質が損なわれる可能性があります。",
      "e1": "最適な動画サイズがわからない場合自動圧縮を試す",
      "e2": "最適なファイル サイズは、圧縮プロセス中に自動的に計算されます。",
      "e3": "これには時間がかかりますが、最適な圧縮サイズと画質が得られます。",
      "e4": "圧縮を開始",
      "e5": "圧縮の進行",
      "e6": "ダウンロード",
      "e7": "初期化中",
      "e8": "通常は約 10 秒かかり、1 回限りです。",
      "e9": "申し訳ありませんが、このビデオ形式は現在サポートされていません。",
      "g1": "動画ファイルが大きすぎて、Instagram、Facebook、YouTube、TikTok などにアップロードしたり、メールで送信したりできませんか?",
      "g2": "この無料のオンライン圧縮ツールを使用して、ビデオをすばやく圧縮し、画質を維持します。",
      "g3": "サポートされている最大ファイル サイズは 2GB です",
      "g4": "次の形式の圧縮がサポートされています",
      "g5": "圧縮ファイルはmp4形式です",
      "k1": "使い方",
      "k2": 'ステップ 1: [ローカル ファイルの選択] をクリックするか、ファイルを指定の領域にドラッグ アンド ドロップします。',
      "k3": "ステップ 2: 目的のファイル サイズを選択する",
      "k4": 'ステップ 3: [圧縮の開始] をクリックし、圧縮が完了したら、[ダウンロードして保存] をクリックしてファイルを保存します。'
    }
  },
  'es': {
    translation: {
      "title": "Comprimir video en línea gratis: sin inicio de sesión ni instalación | Redpanda",
      "meta": "Una herramienta de compresión de video en línea rápida y segura para reducir el tamaño del archivo. Sin suscripciones, cookies o marcas de agua. Uso ilimitado para la seguridad de la información. Tamaño de video comprimible hasta 2GB.",
      "subtitle": "Herramienta gratuita de compresión de video en línea",
      "h1": "Gratis",
      "h1d1": "Sin suscripción, Sin inicio de sesión, Sin cookies",
      "h1d2": "Sin marca de agua, uso ilimitado.",
      "h2": "Segura",
      "h2d1": "El video se procesa en su navegador.",
      "h2d2": "Sin cargas. Sin instalación de software.",
      "h3": "Alta calidad",
      "h3d1": "compresión muy rápida",
      "h3d2": "Comprima a su tamaño de video deseado mientras mantiene la calidad",
      "f1": "Seleccione un documento...",
      "f2": "Pruebe el video de muestra",
      "f3": "o",
      "f4": "Arrastre los archivos aquí (Max 2GB)",
      "f5": "Tamaño del archivo de salida", 
      "f6": "Tamaño sugerido",
      "p1": "Tamaño estimado basado en 3 MB/s para videos de 1080p.",
      "p2": "Ver",
      "p3": "los consejos de youtube",
      "p4": "para más sugerencias",
      "p5": "El video seleccionado probablemente esté bien comprimido.",
      "p6": "Una mayor compresión puede comprometer la calidad de la imagen.",
      "e1": "¿No estás seguro del mejor tamaño? Pruebe la compresión automatizada",
      "e2": "El tamaño se calcularía automáticamente durante la compresión.",
      "e3": "Esto lleva más tiempo pero le dará el mejor tamaño de compresión y calidad de imagen.",
      "e4": "Iniciar compresión",
      "e5": "Progreso de compresión",
      "e6": "Descargar",
      "e7": "inicialización",
      "e8": "Esto suele tardar unos 10 segundos y es una configuración única.",
      "e9": "Lo sentimos, este formato de video aún no es compatible.",
      "g1": "¿Tiene un video demasiado grande para subirlo a Instagram, Facebook, YouTube, TikTok o enviarlo por correo electrónico?",
      "g2": "Puede comprimir fácilmente videos en línea y reducir el tamaño del video sin perder mucha calidad con nuestra herramienta en línea GRATIS.",
      "g3": "El tamaño máximo de archivo admitido es de 2 GB.",
      "g4": "Admite los siguientes formatos",
      "g5": "El video comprimido estará en formato mp4.",
      "k1": "Cómo utilizar",
      "k2": 'Paso 1: haga clic en "Seleccionar archivo" o arrastre y suelte un archivo directamente en el área del cuadro.',
      "k3": "Paso 2: elija el tamaño de archivo de salida deseado.",
      "k4": 'Paso 3: haga clic en el botón "Iniciar compresión" y descárguelo mediante el botón "Descargar" una vez hecho.'
    }
  },
  'fr': {
    translation: {
      "title": "Compresser la vidéo en ligne gratuitement - Pas de connexion ni d'installation | Redpanda",
      "meta": "Outil de compression vidéo en ligne rapide et sûr pour réduire la taille du fichier. Pas d'abonnement, pas de cookie, pas de filigrane. Utilisation illimitée avec sécurité des informations. Taille vidéo compressible jusqu'à 2 Go.",
      "subtitle": "Outil de compression vidéo en ligne gratuit",
      "h1": "Gratuit",
      "h1d1": "Pas d'abonnement, pas de login, pas de cookie",
      "h1d2": "Pas de filigrane, utilisation illimitée.",
      "h2": "Sûr",
      "h2d1": "La vidéo est traitée dans votre navigateur.",
      "h2d2": "Aucun téléchargement. Aucune installation de logiciel.",
      "h3": "Haute qualité",
      "h3d1": "Incroyablement rapide",
      "h3d2": "Compresser à la taille désirée avec peu de différence visuelle",
      "f1": "Sélectionnez un document...",
      "f2": "Essayez un exemple de vidéo",
      "f3": "ou",
      "f4": "Déplacez des fichiers ici (Max 2Go)",
      "f5": "Taille du fichier de sortie", 
      "f6": "Taille suggérée",
      "p1": "Taille estimée sur la base de 3 Mo/s pour les vidéos 1080p.",
      "p2": "Voir",
      "p3": "les conseils de Youtube",
      "p4": "pour d'autres suggestions",
      "p5": "La vidéo sélectionnée est probablement bien compressée.",
      "p6": "Plus de compression peut endommager la qualité de l'image.",
      "e1": "Vous n'êtes pas sûr de la meilleure taille? Essayez la compression automatisée",
      "e2": "La taille serait calculée automatiquement lors de la compression.",
      "e3": "Cela prend plus de temps mais vous donnera la meilleure taille de compression et la meilleure qualité d'image.",
      "e4": "Démarrer la compression",
      "e5": "Progression de la compression",
      "e6": "Télécharger",
      "e7": "Initialisation",
      "e8": "Cela prend généralement environ 10 secondes et il s'agit d'une configuration unique.",
      "e9": "Désolé, ce format vidéo n'est pas encore pris en charge.",
      "g1": "Vous avez une vidéo trop volumineuse pour être téléchargée sur Instagram, Facebook, YouTube, TikTok ou pour être envoyée par e-mail?",
      "g2": "Vous pouvez facilement compresser la vidéo en ligne et réduire la taille de la vidéo sans perdre beaucoup de qualité avec notre outil en ligne GRATUITEMENT.",
      "g3": "La taille de fichier maximale prise en charge est de 2 Go.",
      "g4": "Prend en charge les formats suivants",
      "g5": "La vidéo compressée sera au format mp4.",
      "k1": "Comment utiliser",
      "k2": 'Étape 1 : Cliquez sur "Sélectionner un fichier" ou faites glisser et déposez un fichier directement sur la zone encadrée.',
      "k3": "Étape 2 : Choisissez la taille de fichier de sortie souhaitée.",
      "k4": 'Étape 3 : Cliquez sur le bouton "Démarrer la compression" et téléchargez via le bouton "Télécharger" une fois l\'opération terminée.'
    }
  },
  'ar': {
    translation: {
      "title": "ضغط الفيديو عبر الإنترنت مجانًا - بدون تسجيل دخول أو تثبيت| Redpanda",
      "meta": "أداة ضغط الفيديو عبر الإنترنت سريعة وآمنة لتقليل حجم الملف. لا اشتراك ولا ملف تعريف ارتباط ولا علامة مائية. استخدام غير محدود مع أمن المعلومات. حجم فيديو قابل للضغط يصل إلى 2 جيجابايت.",
      "subtitle": "أداة مجانية لضغط الفيديو على الإنترنت",
      "h1": "حر",
      "h1d1": "لا اشتراك ، لا تسجيل دخول ، لا ملف تعريف ارتباط",
      "h1d2": "لا توجد علامة مائية ، استخدام غير محدود.",
      "h2": "آمن",
      "h2d1": "تتم معالجة الفيديو في متصفحك.",
      "h2d2": "لا تحميلات. لا يوجد تثبيت برامج.",
      "h3": "جودة عالية",
      "h3d1": "يعمل الضغط بسرعة كبيرة",
      "h3d2": "الضغط على الحجم المطلوب مع القليل من الاختلاف البصري",
      "f1": "حدد وثيقة ...",
      "f2": "جرب عينة فيديو",
      "f3": "أو",
      "f4": "اسحب الملفات هنا (2 جيجابايت كحد أقصى)",
      "f5": "حجم ملف الإخراج", 
      "f6": "الحجم المقترح",
      "p1": "الحجم المقدر بناءً على 3 ميجابايت / ثانية لمقاطع الفيديو بدقة 1080 بكسل.",
      "p2": "ألق نظرة على",
      "p3": "نصيحة يوتيوب",
      "p4": "لمزيد من الاقتراحات",
      "p5": "من المحتمل أن يكون الفيديو المحدد مضغوطًا جيدًا.",
      "p6": "قد يؤدي الضغط الإضافي إلى الإضرار بجودة الصورة.",
      "e1": "لست متأكدا من أفضل حجم؟ جرب الضغط الآلي",
      "e2": "سيتم حساب الحجم تلقائيًا أثناء الضغط.",
      "e3": "يستغرق هذا وقتًا أطول ولكنه يمنحك أفضل حجم ضغط وجودة صورة.",
      "e4": "ضغط",
      "e5": "تقدم الضغط",
      "e6": "تحميل",
      "e7": "جار تهيئة",
      "e8": "يستغرق هذا عادةً حوالي 10 ثوانٍ ويكون إعدادًا لمرة واحدة.",
      "e9": "عذرا ، تنسيق الفيديو هذا غير معتمد حتى الآن.",
      "g1": "هل لديك مقطع فيديو كبير جدًا لتحميله على Instagram أو Facebook أو YouTube أو TikTok أو لإرساله عبر البريد الإلكتروني؟",
      "g2": "يمكنك بسهولة ضغط الفيديو عبر الإنترنت وتقليل حجم الفيديو دون فقدان الكثير من الجودة باستخدام أداتنا عبر الإنترنت مجانًا.",
      "g3": "حجم الملف المدعوم Maximun هو 2 جيجابايت.",
      "g4": "يدعم التنسيقات التالية",
      "g5": "سيكون الفيديو المضغوط بتنسيق mp4.",
      "k1": "كيف تستعمل",
      "k2": 'الخطوة 1: انقر فوق "تحديد ملف" أو قم بسحب الملف وإفلاته مباشرة في المنطقة المعبأة.',
      "k3": "الخطوة 2: اختر حجم ملف الإخراج المطلوب.",
      "k4": 'الخطوة 3: انقر فوق الزر "بدء الضغط" وقم بالتنزيل عبر الزر "تنزيل" بعد الانتهاء.'
    }
  },    
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;