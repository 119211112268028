import React from "react";

import "./BlockAdComponent.less";

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

export default class BlockAdComponent extends React.Component {
  componentDidMount() {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      // console.error(error);
    }
  }

  render() {
    return (
      <ins
        className="adsbygoogle block-ad"
        style={{ display: "block" }}
        data-ad-client="ca-pub-1893880936025368"
        data-ad-slot="5889716385"
        data-ad-format="auto"
        data-full-width-responsive="true"
      />
    );
  }
}
