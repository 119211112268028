import { Col, Row } from "antd";
import { useTranslation } from 'react-i18next';
import "./FeatureBlock.less";

interface FBInput {
  iconPath: string;
  title: string;
  descriptions: string[];
}

function FeatureBlock(props: FBInput) {
  const { t } = useTranslation();
  return (
    <Row className="feature-block">
      <Col span={6}>
        <img className="feature-icon" src={props.iconPath} width="62px" height="62px" alt="" />
      </Col>
      <Col span={18}>
        <h1 className="feature-title">{t(props.title)}</h1>
        {
          props.descriptions.map(function(v, i) {
            return <div className="feature-description" key={i}>{t(v)}</div>;
          })
        }
      </Col>
    </Row>
  );
}

export default FeatureBlock;
