import { useEffect } from "react";
import "./SideAdComponent.less";

declare global {
  interface Window {
    adsbygoogle: any;
  }
}

interface SideAdInput {
  adSlot: string;
}

export default function SideAdComponent(props: SideAdInput) {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      // console.error(error);
    }
  });
  return (
    <ins
      className="adsbygoogle side-ad"
      style={{ display: "block" }}
      data-ad-client="ca-pub-1893880936025368"
      data-ad-slot={props.adSlot}
      data-ad-format="rectangle"
      data-full-width-responsive="true"
    />
  );
}
