import { Dropdown, Menu } from "antd";
import "./LanguageSelector.less";

import { GlobalOutlined } from "@ant-design/icons";

const menu = (
  <Menu
    items={[
      {
        label: <a href="/en">English</a>,
        key: "1",
      },
      {
        label: <a href="/ru">Pусский</a>,
        key: "2",
      },
      {
        label: <a href="/zh">简体中文</a>,
        key: "3",
      },
      {
        label: <a href="/zh">繁体中文</a>,
        key: "4",
      },
      {
        label: <a href="/tr">Türkçe</a>,
        key: "5",
      },
      {
        label: <a href="/id">Indonesia</a>,
        key: "6",
      },
      {
        label: <a href="/in">हिंदी</a>,
        key: "7",
      },
      {
        label: <a href="/jp">日本语</a>,
        key: "8",
      },
      {
        label: <a href="/es">Español</a>,
        key: "9",
      },
      {
        label: <a href="/fr">Français</a>,
        key: "10",
      },
      {
        label: <a href="/ar">عربي</a>,
        key: "11",
      },
    ]}
  />
);

const languageNameMap = new Map([
  ["en", "English"],
  ["ru", "Pусский"],
  ["zh", "简体中文"],
  ["tw", "繁体中文"],
  ["tr", "Türkçe"],
  ["id", "Indonesia"],
  ["in", "हिंदी"],
  ["jp", "日本语"],
  ["es", "Español"],
  ["fr", "Français"],
  ["ar", "عربي"]
]);

interface LSInput {
  lang: string;
}

function LanugageSelector(props: LSInput) {
  return (
    <Dropdown overlay={menu} className="language-selector">
      <div>
        <GlobalOutlined /> {languageNameMap.get(props.lang)}
      </div>
    </Dropdown>
  );
}

export default LanugageSelector;
